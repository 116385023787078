import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import AccordionRow from '~components/AccordionRow'
import Multi from '~components/Multi'

const AccordionModule = ({ className, title, rows }) => {
	return (
		<Wrap className={className}>
			<Left>
				<Title>
					<Multi>{title}</Multi>
				</Title>
			</Left>
			<Right>
				<Accordion>
					{rows?.map(row => (
						<AccordionRow key={row._key} title={row.title}>
							<Text className='accordion'><Multi>{row.text}</Multi></Text>
						</AccordionRow>
					))}
				</Accordion>
			</Right>
		</Wrap>
	)
}

const Wrap = styled(Section)`
	background-color: var(--beige);
	padding-top: 80px;
	padding-bottom: 80px;
	${mobile}{
		padding-top: 50px;

	}
`
const Left = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
	}
`
const Title = styled.h1`
	${mobile}{
		margin-bottom: 80px;
	}
`
const Right = styled.div`
	grid-column: 6/13;
	${mobile}{
		grid-column: span 12;
	}
`
const Accordion = styled.div`
	border-bottom: 1px solid var(--black);
`
const Text = styled.div`
	padding-bottom: 34px;
	${mobile}{
		padding-top: 8px;
		padding-bottom: 36px;
	}
`
AccordionModule.propTypes = {
	className: PropTypes.string,
	title: PropTypes.object,
	rows: PropTypes.array,
}

export default AccordionModule