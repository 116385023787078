import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Collapse } from 'react-collapse'
import Multi from '~components/Multi'

const AccordionRow = ({ className, title, children }) => {
	const [open, setOpen] = useState(false)
	return (
		<Wrap className={className} >
			<Header onClick={() => setOpen(!open)} className='nav'>
				<Multi>{title}</Multi>
				<PlusMinus>{open ? '–' : '+'}</PlusMinus>
			</Header>
			<Collapse isOpened={open}>
				<div>
					<Children >
						{children}
					</Children>
				</div>
			</Collapse>
		</Wrap>
	)
}

const Wrap = styled.div`

`

const Children = styled.div`

`

const Header = styled.button`
	border-top: 1px solid var(--black);
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: 72px;
	align-items: center;
`
const PlusMinus = styled.span`
	font-size: 22px;
	line-height: 1;
`

AccordionRow.propTypes = {
	className: PropTypes.string
}

export default AccordionRow