import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import ImageText from '~components/ImageText'
import QuoteModule from '~components/QuoteModule'
import AccordionModule from '~components/AccordionModule'

const RenderModules = ({ className, modules }) => {
	if(!modules) return null
	return (
		<div className={className}>
			{modules.map(module => (
				<RenderModule module={module} key={module._key}/>
			))}
		</div>
	)
}

const RenderModule = ({ module }) => {
	switch (module._type) {
	case 'textModule':
		return <ImageText content={module} />
	case 'quoteModule':
		return (
			<QuoteModule 
				quote={module.quote} 
				attribution={module.attribution} 
				image={module.image} 
				color={module.background?.value}
			/>
		)
	case 'accordionModule':
		return (
			<AccordionModule 
				title={module.title}
				rows={module.rows}
			/>
		)
	default:
		return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.object,
}

RenderModule.propTypes = {
	module: PropTypes.object,
}

export default RenderModules