import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, Tag, StyledLink, ArrowDown, ArrowRight } from '~styles/global'
import Section from '~components/Section'
import Multi from '~components/Multi'
import SanityLink from '~components/SanityLink'
import Image from '~components/Image'
import { Link } from 'gatsby'

const PostHeader = ({ className, title, subtitle, tags, image, links, type, backText, featuredDownloads }) => {
	return (
		<Wrap className={className}>
			<Left>
				<Top>
					<Title className='h2'>
						<Multi>
							{title}
						</Multi>
					</Title>
					<TitleMob>
						<Multi>
							{title}
						</Multi>	
					</TitleMob>
					<SubTitle className='caption'>
						<Multi>
							{subtitle}
						</Multi>
					</SubTitle>
					{featuredDownloads?.length > 0 && 
						<FeaturedDownloads>
							{featuredDownloads?.map(d => (
								<div key={d?.text?.english}><FeaturedDownload className='tag' link={d.link} ><Multi>{d.text}</Multi><StyledArrowDown /></FeaturedDownload></div>
							))}
						</FeaturedDownloads>
					}
					<Tags>
						{tags?.map(tag => (
							<Tag className='tag' key={tag?.title?.english}><Multi>{tag.title}</Multi></Tag>
						))}
					</Tags>
				</Top>
				<Links>
					{links?.map(link => (
						<StyledLink 
							className='link-sm' 
							key={link.text?.english} 
							link={link.link}
						>
							<Multi>{link.text}</Multi>
							{link.link?.linkType === 'file' ? 
								<ArrowDown />
								:
								<ArrowRight />
							}
						</StyledLink>
					))}
				</Links>
			</Left>
			<Right>
				<BackLink className='link-sm' to={`/${type}s`}><Multi>{backText}</Multi></BackLink>
				<StyledImage image={image} aspectRatio={1.42}/>
				<LinksMob>
					{links?.map(link => (
						<StyledLink className='link-sm' key={link.text?.english} link={link.link}>
							<Multi>{link.text}</Multi>
							{link.link?.linkType === 'file' ? 
								<ArrowDown />
								:
								<ArrowRight />
							}
						</StyledLink>
					))}
				</LinksMob>
			</Right>

		</Wrap>
	)
}

const Wrap = styled(Section)`
	padding-top: 248px;
	padding-bottom: 50px;
	background-color: var(--beige);
	${mobile}{
		padding-top: 189px;
		padding-bottom: 31px;
	}
`
const Left = styled.div`
	grid-column: span 4;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${mobile}{
		grid-column: span 12;
	}
`
const Top = styled.div`
	
`
const Title = styled.h1`
	margin-top: 40px;
	margin-bottom: 37px;
	${mobile}{
		display: none
	}
`
const TitleMob = styled.h1`
	margin-bottom: 44px;
	display: none;
	${mobile}{
		display: block;
	}
`
const SubTitle = styled.div`
	margin-bottom: 28px;
	${mobile}{
		margin-bottom: 65px;
	}
`
const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`
const Links = styled.div`
	display: flex;
	flex-wrap: wrap;
	${mobile}{
		display: none;
	}
`
const LinksMob = styled.div`
	flex-wrap: wrap;
	display: none;
	${mobile}{
		display: flex;
	}
`
const Right = styled.div`
	grid-column: span 8;
	text-align: right;
	${mobile}{
		grid-column: span 12;
	}
`
const StyledImage = styled(Image)`
	${mobile}{
		margin-top: 34px;
		margin-bottom: 33px;
	}
`
const BackLink = styled(Link)`
	margin-bottom: 13px;
  display: inline-block;
	transition: color 0.25s;
	:hover	{
		color: var(--purple)
	}
	${mobile}{
		display: none;
	}
`
const FeaturedDownloads = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
`
const FeaturedDownload = styled(SanityLink)`
	display: inline-flex;
	background-color: var(--lightOrange);
	color: var(--black);
	margin-right: 10px;
	padding: 6px 16px 4px;
	margin-bottom: 10px;
	align-items: center;
	justify-content: space-between;
	:hover {
		background-color: var(--orange);
	}
`
const StyledArrowDown = styled(ArrowDown)`
	top: -2px;
	margin-left: 10px;
`
PostHeader.propTypes = {
	className: PropTypes.string,
	title: PropTypes.object,
	subtitle: PropTypes.object,
	tags: PropTypes.object,
	image: PropTypes.object,
	links: PropTypes.array,
	type: PropTypes.string,
	backText: PropTypes.object,
	featuredDownloads: PropTypes.array,
}

export default PostHeader