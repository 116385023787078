import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import PostHeader from '~components/PostHeader'
import RenderModules from '~components/RenderModules'
import PostGrid from '~components/PostGrid'
import ProjectsSlider from '~components/ProjectsSlider'
import useBreakpoint from '~utils/useBreakpoint'
import useSiteSettings from '~utils/useSiteSettings'

const Post = ({ data }) => {
	const page = data?.resource || data?.project
	const { isTablet } = useBreakpoint()
	const { backToIndex } = useSiteSettings()

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<PostHeader 
				title={page?.title} 
				subtitle={page?.subtitle} 
				tags={page?.tags} 
				image={page?.headerImage} 
				links={page?.headerLinks}
				type={page?._type}
				backText={backToIndex}
				featuredDownloads={page?.featuredDownloads}
			/>
			<RenderModules modules={page?.content} />
			{data?.resource ? 
				<PostGrid posts={isTablet ? page?.relatedResources.slice(0, 2) : page?.relatedResources.slice(0, 4)} title={data?.sanitySiteSettings?.relatedArticles} rows={4}/>
				:
				<ProjectsSlider title={data?.sanitySiteSettings?.relatedProjects} projects={page?.relatedProjects}/>
			}
			<DataViewer data={data} name="page"/>
		</>
	)
}

Post.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query PostQuery($slug: String, $isResource: Boolean = false, $isProject: Boolean = false) {
		resource: sanityResource(slug: {current: {eq: $slug}}) @include(if: $isResource) {
			_type
			title {
				...multiString
			}
			subtitle {
				...multiText
			}
			tags {
				title {
				...multiString
				}
				slug {
					current
				}
			}
			featuredDownloads {
				...button
			}
			headerImage {
				...imageWithAlt
			}
			headerLinks {
				...button
			}
			content: _rawContent(resolveReferences: {maxDepth: 10})
			relatedResources {
				...resourceThumb
			}
			seo {
				...seo
			}
		}
		project: sanityProject(slug: {current: {eq: $slug}}) @include(if: $isProject) {
			_type
			title {
				...multiString
			}
			subtitle {
				...multiText
			}
			tags {
				title {
				...multiString
				}
				slug {
					current
				}
			}
			featuredDownloads {
				...button
			}
			headerImage {
				...imageWithAlt
			}
			headerLinks {
				...button
			}
			content: _rawContent(resolveReferences: {maxDepth: 10})
			relatedProjects {
				...projectThumb
			}
			seo {
				...seo
			}
		}
		sanitySiteSettings{
			relatedProjects{
				...multiString
			}
			relatedArticles{
				...multiString
			}
		}
  }
`

export default Post